.aui-CarInfoActions {
  display: flex;
}

.aui-CarInfoActions-col:nth-child(odd) {
  @media (min-width: 480px) {
    padding-right: 10px;
  }
}

.aui-CarInfoActions-row {
  max-width: $media-xs;

  .aui-CarInfoActions-col:nth-child(1) {
    order: 1;
    width: 100%;
  }
  .aui-CarInfoActions-col:nth-child(2) {
    order: 3;
    width: 100%;
  }
  .aui-CarInfoActions-col:nth-child(3) {
    order: 2;
    width: 100%;
  }
  .aui-CarInfoActions-col:nth-child(4) {
    order: 4;
    width: 100%;
  }

  @media (min-width: $media-xs) {
    max-width: auto;

    .aui-CarInfoActions-col:nth-child(1) {
      order: 1;
    }
    .aui-CarInfoActions-col:nth-child(2) {
      order: 2;
    }
    .aui-CarInfoActions-col:nth-child(3) {
      order: 3;
    }
    .aui-CarInfoActions-col:nth-child(4) {
      order: 4;
    }
  }
}
