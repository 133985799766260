// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.caret {
  position: absolute;
  z-index: 1;
  top: 18px;
  right: 20px;
  padding-right: 20px;
  font-family: $font-family-GeometriaBold;
  font-size: 10px;
  letter-spacing: 0.8px;
  color: $color-dark;

  &:after {
    content: '';
    position: absolute;
    top: -3px;
    right: 0;
    width: 10px;
    height: 10px;
    border-left: 2px solid $color-dark;
    border-bottom: 2px solid $color-dark;
    transform: rotate(-45deg);
    pointer-events: none;
    transition: color 0.3s ease;
  }

  &.isOpen:after {
    top: 3px;
    transform: rotate(135deg);
  }

  &:hover {
    color: $color-blue;
    &:after {
      border-color: $color-blue;
    }
  }
}
