.aui-input {
  border-color: #cdcdcd;
  height: 45px;
  font-size: 16px;
  padding: 13px 20px;

  @media (min-width: $media-md) {
    font-size: 13px;
  }

  &:focus {
    border-color: #005693;
  }

  &::placeholder {
    color: #929699;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
