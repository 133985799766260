// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.bill {
  font: 12px/1.4 Verdana, sans-serif;
  color: #1a212d;
  padding: 30px;
  max-width: 960px;

  table {
    width: 100%;
    border: 0;
    border-collapse: collapse;
  }
}

.table1 {
  margin-bottom: 25px;
  td {
    vertical-align: top;
    padding: 3px;
    border: 1px solid #000;
  }
  .label1 {
    padding-top: 10px;
    border-top: 0;
  }
  .label2 {
    padding-right: 15px;
  }
  .name {
    font-size: 110%;
  }
}

.title {
  font-size: 130%;
  font-style: italic;
  font-weight: bold;
  padding: 0 0 3px 3px;
  border-bottom: 1px solid #000;
  span {
    display: inline-block;
    border-bottom: 1px solid #000;
  }
  &__num {
    min-width: 120px;
  }
  &__day,
  &__month {
    min-width: 25px;
    text-align: right;
  }
  &__year {
    min-width: 50px;
    text-align: right;
  }
}

.table2 {
  margin-bottom: 20px;
  td {
    padding: 3px;
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      font-weight: bold;
      font-style: italic;
      padding-top: 13px;
      padding-bottom: 13px;
      border-bottom: 1px solid #000;
    }
  }
}

.table3 {
  margin-bottom: 5px;
  th,
  td {
    padding: 3px;
    border: 1px solid #000;
  }
  th {
    font-weight: bold;
    font-style: italic;
  }
  td {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
  .nowrap {
    white-space: nowrap;
  }
  tfoot {
    td {
      font-weight: bold;
      font-style: italic;
      text-align: right;
      border: 0;
    }
  }
  .total-price {
    font-size: 120%;
  }
}

.total-price-info {
  font-weight: bold;
  font-style: italic;
  padding: 3px;
  margin-bottom: 12px;
  border-bottom: 2px solid #000;
}

.total-price-person {
  padding: 3px;
  margin-bottom: 35px;
}

.total-manufacturer-price {
  padding: 3px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 110%;
}

.title-manufacturer-disclaimer {
  padding: 3px;
  font-size: 110%;
}

.footer-person {
  display: flex;
  align-items: center;
  padding-left: 3px;
  &__who {
    width: 27%;
    padding-right: 20px;
  }
  &__autograph {
    width: 19%;
    padding-right: 15px;
  }
  &__name {
    width: 54%;
    padding-left: 15px;
  }
  &__field {
    min-height: 19px;
    text-align: center;
    border-bottom: 1px solid #000;
  }
  &__label {
    text-align: center;
    font-size: 85%;
  }
}

.footer-date {
  margin-bottom: 40px;
  font-weight: bold;
  font-style: italic;
  padding-left: 3px;
  span {
    display: inline-block;
    border-bottom: 1px solid #000;
  }
  &__num {
    min-width: 30px;
  }
  &__day {
    min-width: 25px;
  }
  &__month {
    min-width: 90px;
  }
  &__year {
    min-width: 50px;
  }
}

@page {
  margin-right: 1cm;
  margin-left: 1cm;
  size: auto;
}

@media print {
  .bill {
    padding: 0;
  }
}
