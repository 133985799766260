// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

$gray: #929699;

.wrap {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  background-color: $color-white;
  padding: 20px 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transform: translate(0, -100%);
  transition: transform 0.25s ease-in-out;
  opacity: 0;

  &.active {
    transform: translate(0, 0);
  }

  .row {
    align-items: center;
  }
}

.carTitle {
  font-size: 18px;
  font-family: $font-family-GeometriaBold;
}

.price {
  font-size: 32px;
  font-family: $font-family-GeometriaBold;

  svg {
    height: 1em;
    margin-left: 10px;
    font-size: 22px;
    color: $color-blue;
  }
}

.priceWithDiscount {
  text-decoration: line-through;
  color: $gray;
  font-family: $font-family-GeometriaBold;
}

.priceDiffrence {
  font-family: $font-family-GeometriaBold;
  color: $color-blue;
  text-align: right;
}

.carDescr {
  color: $gray;

  span {
    text-transform: lowercase;
  }
}

@media (min-width: $media-lg) {
  .wrap {
    z-index: 100;
    opacity: 1;
  }
}
