.aui-Button,
.ant-form button {
  outline: none;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

// на сайтах дилера footer залезает (margin-top: -7px) на кнопку
.aui-CarsLoadmore-btn {
  margin: 7px 0;
}
