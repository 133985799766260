// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

$red: #ff4d4f;
$text-dark: #252b33;
$text-gray: #929699;

$bg-gray: #f4f4f4;
$bg-blue: #005693;

.form {
  margin-top: 20px;
  color: $text-dark;
  font-size: 13px;
  font-family: 'Verdana', sans-serif;

  input {
    width: 100%;
    padding: 10px 6px;
    border: 1px solid #cdcdcd;
    font-size: 16px;
    color: $text-dark;
    outline: none;

    &::placeholder {
      color: rgba($text-dark, 0.65);
    }

    @media (min-width: $media-md) {
      padding: 10px;
      font-size: 13px;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 12px;
  }

  :global {
    .rc-select-search__field {
      padding: 0;
    }
    .ant-form-item-has-error {
      input {
        border-color: $red;
      }
      .ant-form-item-explain {
        display: block;
      }
    }
    .ant-form-item-explain {
      display: none;
      color: $red;
    }

    .ant-radio-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .ant-radio-wrapper {
      margin: 0;
      font-size: 10px;
      font-family: $font-family-GeometriaBold;
      letter-spacing: 0.3px;
      color: $text-gray;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;

      .radioIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
        border-radius: 4px;
        background-color: $bg-gray;

        img {
          max-width: 33px;
        }
      }

      &-checked {
        color: $text-dark;
        .radioIcon {
          background-color: $bg-blue;
        }
      }
    }

    .ant-radio {
      display: none;
    }

    span.ant-radio + * {
      padding: 0;
    }

    .ant-picker {
      padding: 0;
      border: none;
    }
    .ant-picker-suffix {
      display: none;
    }
  }
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    width: 49%;
  }
}

.closeForm {
  margin: 0;
  padding: 0;
  font-size: 28px;
  line-height: 1;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 5px;
}

.timeZoneTxt {
  width: 100%;
  display: block;
  margin-top: 10px;
  font-size: 11px;
  color: $text-gray;
}
