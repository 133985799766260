// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.popover {
  :global {
    .ant-popover-inner {
      border: 1px solid $color-blue;
    }

    .ant-popover-inner-content {
      padding: 1px 8px;
      font-size: 13px;
    }

    a {
      color: $color-dark;

      &:hover {
        color: $color-blue;
      }
    }
  }

  &:global(.ant-popover-placement-bottomRight),
  &:global(.ant-popover-placement-bottomLeft) {
    :global {
      .ant-popover-arrow {
        top: 7px;
        right: 7px;
        border-right-color: $color-white;
        border-bottom-color: $color-white;
        box-shadow: -1px -1px 0px $color-blue !important;
      }
    }
  }

  &:global(.ant-popover-placement-bottomLeft) {
    :global {
      .ant-popover-arrow {
        left: 7px;
      }
    }
  }
}
