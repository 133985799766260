.aui-Textarea {
  border-color: #cdcdcd;
  font-size: 16px;
  padding: 13px 20px;

  @media (min-width: $media-md) {
    font-size: 13px;
  }

  &:focus {
    border-color: #005693;
  }
}
