.csp-widget {
  font: $body-base-font;
  overflow: hidden;

  :focus {
    outline: none;
  }

  @media (min-width: $media-lg) {
    margin-top: -50px;
    padding-top: 45px;
  }
}
