.azgazui__card-credit {
  margin-bottom: 15px;

  @media (min-width: $media-xs) {
    display: flex;
    flex-wrap: wrap;
    max-width: $media-xs;

    .azgazui__card-credit-item:nth-child(odd) {
      width: 100%;
      flex: 0 0 61%;
      max-width: 61%;
      padding-right: 30px;
    }
    .azgazui__card-credit-item:nth-child(even) {
      margin-left: -10px;
    }
  }

  @media (min-width: $media-sm) {
    flex: 0 0 $media-xs;
    max-width: $media-xs;
  }

  @media (min-width: $media-lg) {
    .azgazui__card-credit-item:nth-child(even) {
      margin-left: 0;
    }
  }
}
