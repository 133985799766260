.azgazui__car-info-properties {
  margin: 0;
  padding: 40px 0;
  border-bottom: 1px solid $color-gray-lighter2;

  .azgazui__car-info-properties {
    padding: 0;
    border: none;
  }

  @media (min-width: $media-md) {
    h2 {
      margin-bottom: 40px;
    }
  }

  @media (min-width: $media-xl) {
    font-size: 12.5px;

    .col-7 {
      padding-right: 0;
    }
  }
}
