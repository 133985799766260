// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.switch {
  border: none;
  background: none;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
  top: 19px;
  right: 18px;

  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAnCAYAAAB9qAq4AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQvSURBVHgBxZhNcts2FMffA2RZbTe6QZQTWN5112TXaTf2CSJv2soZWdEJbJ9A/qisiZsZKSdoN+10F/UEkU8Q+gZaNY5GwMt7ECkpMgkxJBP/ZzgDECTwwwMe8ACEgnRx9bILBA2XQRgeHf7agQKkoQA5OIAXDFZxD8D3P/70M/77z98jyCmEnOp2+zW9rd6F2YCtWOVaq5yemA/lx53OwQRySEFOMVx7kSF7SmDPw1xVle9eQE7lsmBovTecrPETsMV25b0uT98VZcVcFixVSs9COCCyrwVEniKtmBmw2x1UGaoRZgOrYBiV2WnljOeisxqiasu3kFGZAXX5bg9C6zHFqNNsBlFZkVbMPsSojqOkAXO6XlyUFTMBXvT6DVhab7hqvUhFWTGbBTdYL9LqvBQrQpamkgrckJT/r3Ef6lqXdhQROwXUiagWLiHOekfNXw7Ao/Or/hBBPXMZHnJEDDgRWLI3VhZ2y8+sMk5ainAO06urstrTSj+6B+GRQfs4bng/6Wif10pa7DTJEniFY16vJg5+CsNOpxng5dXLPQL4E9JIJj3ShHs1JsD/OCA4S/Pbea9/ohB32B41bqsOKWUA9pE3euldbY0kEAiLcIvGBoZ0ACU73mSttOr2enWeoFWNVCetauwIO0BYjYEPBJAkhYDjGZr9oiCySuAVlgYYwipZZCVBQHVt9TE8sDRstSM4cUJl7rb2QbzJvaDGRe96AA8k1zYzhNlAljAl7s3e+BQeGPIeHDPJdFusg+FyEIVOPOa8xj33r3FfGk4ynyzUDwHpg7sH+LUhN8HFAn4tyDRwiYBfGjItnBcwDtIYrqjVHEEOdXvXdd5B3qaBE3nDLfejbOCROOqAvJpuBYv0WiQep43xIFqObOaa5D3jisI6Alc30cbAYSNgtIEjUn7rLVqdjwrdC1JiPvUVynyJ0tbCDRQkMvY2TFZ/f/Xqke9bL2BpObygrB1BQUJYjga9n+76vvUCWmUXFpwBbpx/ctMgz6bvDOgloNbe70u+QtR6ByyFhMkeLOcXvT2VUM2d3C7610NzZ04lZI/9QTx5exo2IpF2srwWjDxYous4Dxawy/4fx+4uJoRzImrIjZeUxVn0czzZCxh5sEW6TQLj64+T6IDFc2vEZ+HXS057IpdL4Tl6reV0npwIuOrBPMyL4ZXGeDjfroPJLtM6/O1p+7DZMIS7vCyNwl/4hKgGcvZZBU3ryYlb3eUln/b0/LSHxuyLk2itBrDSYwGbGTpN2v4c0PyQX1t5Hcg94rwCV5+rv9U6/CuujkQnmYGd6NDA7GldHdNI63lzCB4dzcuHa6DOotG9zbyt5BXCGyywN77hifRk8YIrlfsW+23lrHPw+dseD7M4UhvWRkGmBmQBdJVy75XCH4yl26xgq3IRkoUnUqc19sZ8983QV+dH/hxd5EVx2kYAAAAASUVORK5CYII=');
    background-position: center;
    background-size: 20px 19px;
    background-repeat: no-repeat;
  }

  &:hover {
    .icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAnCAYAAAB9qAq4AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPDSURBVHgBxZi9UttAEMf39MGk9BtEqVMAXbpAl9JOxpAOp8wMjuEJDE9gsElt0yWQmVCmw3kCSJEa+Q3cxpbusnsnS/KHTkIS+D9jz53ufPfz3u3drhiUpb1uB4A1VIUN4OrwGEqQCWVIwR1h6UXweQOv3zH4+2sIBcWgqD52HODWg6oIF4esYIE+Y5h4r+DmeAwFZEBRcbsVlgWc4oPzoFaBDeMICqqYBaX17Fskc6T1Jv62fL4hLVqKFYtZkFsHCo7ELiWIhCnPivkBq51K5LVkPW8Qtk34GZD11BQt1fe5AW2zGlpPGEO0nBu2lWjF/IDMaIfl6fR0qb0kK+YDrHcbkfXwUI5bb6aSrJgPMM164eh8EKu0IM9UiS20JDY4WNoCZm/iGYdLJLAsn6nlIutdH34CnfZ6A/w+CGpjHMfFMVy07h8Q3JV1D+6TjiIFWO/gxEYVhPlyCUIndca52j5zN41WBH+Pc48l/ITLrcPwH6I3wk/IJDGWAwHDgdhv+H54lulne70T/N7EjwO0IlnlQw0BLx6iwzYEcSWEgBEYWPaYC76Fy/DZhTJU/7qFy1sBE1eLMwcInskVW4Bn0oJCMaF5p1atNIi8InjG+xDAGgg2lA0MH9h+G9YtwydvV5ZEJzRg6tXUkqKYaED9og/r0n63j1ANVUEmPMIM6d6Gv7t2yEW4CTJJL55JHgcmhk7MUX0ynHFPDEe1+YN6HZAauGXA54ZMgVsN+FyQGeCSAZ8aMiOcHnAVJIdd+NEcQhHJe9+6ywJH0odb3+iHeOXN5Hn3UFRTimQCLUbiK5QhHmROUBgXzXGlaAwWnrmpgUMGwGAQuqvLkghXxUnrqgek/RIKY7SyJPgoKFXg/cVLXVc9ILecsMxwv5Sm2L62xLaupx7Q5JEFOaTvP/L6Kn5S5UeATGj7p+xBYzMs6jyY8hd6w0WhPb32oGBDBxr3ZB6b4/GAzAkKq5MaCdZrq3cxLEorKSKiZ9S2CvQRnpwCOPNgMUoGgxMIszwZ/F7Gep7ABr5cknn04tDZPDkZMO7BTETLS5Nt2HdLYHTLXDd34arZAOFth5E65TuM9WXuEwfN6MnJV90HzPbMMNurAfcwsLX7cwkWQdA7waTrj4AYtGNbhabEXJirZJ/AZ+NfNW9WDWFBMnpsz7EOGHTkiBmZK8GuvwxAJ9U+mAcNLAqx8TUnhD5YqPduscdO9IDyYnaOCftZrmtvv3uEf6w1Z1FaBdoauQAlJO0b4y3uw1FusLjorPTNHTmmgbfTP3+gG/M/nQflhfHu3EAAAAAASUVORK5CYII=');
    }
  }
  &.active {
    .icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAnCAYAAAB9qAq4AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKASURBVHgBzZg/ctNAFId/u7IYytwgoqYI6eiIO0qHGQc6fACM7RNkcgKBwwFEx8TMQEkX3wBTUKPcwC3+o+W9FYF4Yu1au5atb8Yzkne1+/lpn/S8AtvibBgDopOfiARXbwbYAgG2QS7Xp6OHfz9P8fi5wM9vY3gi4MurOELW+LWmZYrZ4hG+DqbwQMKXLOwVtBzggezDE78I6uiF14CKCnp4R9EvglnjtUGO8Y6iewRbMU0efrcIMl5RdI9gGLQ2kGO8ouguKOT55p1lT0fcATfB9rCzYfRucY6im2Cp6P2bqgcHipOEb0mIiI6eQIRHUBQFKDrW3zndLnDCKKQ0RkqPgB9QWarPF5gUJVEu2I5pYtmCCg63IOEKy09o7qmWn2UJSacCZ5eUjfiCOrLEKa1BEaOuBCKWJbNxx6hI0n0fo64okUjMF6d0lKJ2kNN8fiF1estls16S5DIjJ53Ft+jSKaDSSUTYK//l+Gz1Qb13yVU55v6bZG+S9+WY9a+6nUuul2OK38U7kyyWY8wVdeWSZjnGXG594gvFBFWh5Ngkx2xQD4oIVSGocrKwgaB9EA8iWwezINeJ1XKAFx8OTR3MglkjQtU01LGp2SwYZFVHkNdhZGq2rEF5hKrJzHNYBEWEqrFkskWwZAbnxe9HlCMyNRYLlslgFsvQxKjbxFW3A7U4LlGpGzO5UXgZZ7Bt/5UlFC7wuTte+X404LdPU+9ACJxbl0qeyTdrmwov0v9PC81SLTZ6m8BE3p5YRbPiuczFQvvymnqc3BGjgcR72k5757Sd9nLYpx/WWxHlu8BLw0lQS/JGkXxG2XbjLHYXrpCWwYkeU9IOwu9lYhrzD7eA/ZJMBtMEAAAAAElFTkSuQmCC');
    }
  }
}

.popoverActive {
  &:global(.ant-popover-placement-bottomRight),
  &:global(.ant-popover-placement-bottomLeft) {
    :global {
      .ant-popover-arrow {
        border-color: $color-blue;
      }
    }
  }
  :global {
    .ant-popover-inner {
      background-color: $color-blue;
    }
    .ant-popover-inner-content {
      color: $color-white;
      white-space: nowrap;
    }
  }
}

@media (min-width: $media-xl) {
  .switch {
    right: 32px;
  }
}
