// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.title {
  font-family: $font-family-GeometriaBold;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #1a212d;
}

.panel {
  margin-bottom: 30px;
  :global {
    .ant-collapse-content-box {
      height: 350px;
    }
    .scrollbar-container {
      padding-right: 25px;
    }
  }
}

.success {
  color: $color-green;
}

.select:global(.rc-select-focused .rc-select-selection),
.select:global(.rc-select-focused .rc-select-selection:hover) {
  border-color: #005693 !important;
}

.select {
  :global {
    .rc-select-search__field {
      color: #0c121c;
    }
  }
}

.innTitle {
  display: flex;
  justify-content: space-between;
}

.innHint {
  display: block;
  width: 16px;
  height: 16px;
  background: url(./img/question.svg) no-repeat center center / auto 16px;
}

.innHintText {
  font-size: 13px;
}

.innHintPopover {
  :global {
    .ant-popover-inner {
      padding: 13px 15px;
    }
  }
}

.disclaimer {
  font-size: 11px;
  color: #929699;
}
