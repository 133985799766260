.aui-Select {
  .rc-select-search__field {
    text-overflow: ellipsis;
    font-size: 16px;

    @media (min-width: $media-md) {
      font-size: 13px;
    }
  }
}

.aui-Select-dropdown {
  .rc-select-dropdown-menu-item {
    display: block;
    padding-top: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
