.aui-FiltersWrap-head {
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .azgazui__title-h1 {
      margin: 20px 0;
    }

    .aui-FiltersWrap-toggle {
      margin-bottom: 0;
    }
  }
}
