.azgazui__cars-item {
  position: relative;
}

.azgazui__cars-item-name-grade {
  display: block;
  font-size: 15px;
  text-transform: none;
}

.azgazui__cars-item-body {
  position: relative;
}

.azgazui__cars-item-name {
  padding-right: 25px;
}
