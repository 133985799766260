.aui-checkbox__icon {
  border-radius: 4px;
  border-width: 1px;
  background: #fff;
  width: 22px;
  height: 22px;

  &:before {
    top: 5.5px;
    left: 4.5px;
    width: 12px;
    height: 6px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    transition: none;
  }
}

.aui-checkbox__input:checked ~ .aui-checkbox__icon {
  background-color: #005595;
  border-color: #005595;

  &:before {
    border-bottom-color: #fff;
    border-left-color: #fff;
  }
}

.aui-checkbox__input:disabled ~ .aui-checkbox__icon {
  background-color: #cdcdcd;
  border-color: #cdcdcd;

  &:before {
    border-bottom-color: #fff;
    border-left-color: #fff;
  }
}

.aui-checkbox__input:focus ~ .aui-checkbox__icon {
  border-color: #005595;
}
