.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  display: none;
}

.ant-picker-time-panel .ant-picker-header-view {
  font-size: 12px;
}

.ant-picker-header {
  padding: 0 4px !important;
}

.ant-picker-date-panel {
  width: 245px !important;

  .ant-picker-content {
    width: 230px !important;

    th {
      width: 30px !important;
    }
  }
  .ant-picker-body {
    padding: 8px !important;
  }
}

.ant-picker-time-panel-column {
  width: 46px !important;
}

@media (min-width: $media-md) {
  .ant-picker-time-panel .ant-picker-header-view {
    font-size: 14px;
  }

  .ant-picker-date-panel {
    width: 280px !important;

    .ant-picker-content {
      width: 252px !important;

      th {
        width: 36px !important;
      }
    }
    .ant-picker-body {
      padding: 8px 12px !important;
    }
  }

  .ant-picker-header {
    padding: 0 8px !important;
  }

  .ant-picker-time-panel-column {
    width: 56px !important;
  }
}
