// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.collapse {
  background: transparent;
  border-radius: 0;

  :global {
    /* Заголовок */
    .ant-collapse-item > .ant-collapse-header {
      color: #1a212d;
      text-transform: uppercase;
      font-size: 13px;
      font-family: $font-family-GeometriaBold;
      padding: 15px 40px 15px 20px;
      border-bottom: none;
    }

    /* Контент */
    .ant-collapse-content {
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 5px 20px 15px 20px;
    }
  }
}
