.ant-picker-ok {
  .ant-btn {
    min-width: 40px;
    background-color: $color-green;
    border-radius: 20px;
    border: none;
    color: $color-white;
    cursor: pointer;
    transition: background-color 0.15s;

    &:hover {
      background-color: darken($color-green, 10);
    }
  }
}
