// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.price {
  color: #929699;
  text-decoration: line-through;
  font-size: 16px;
  font-family: $font-family-GeometriaBold;
  margin-right: 12px;
}

.calcDiscount {
  font-size: 13px;
  display: block;
  line-height: 1.2;
}

.openButton {
  display: inline-flex;
  align-items: center;
  padding: 0;

  font-family: $font-family-GeometriaMedium;
  color: $color-blue;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
}

.percent {
  height: 1em;
  margin-right: 6px;

  font-size: 24px;
  color: #005590;
  font-family: $font-family-GeometriaBold;
  text-shadow: 0 0 1px #005590;
}

.header {
  position: relative;
  padding-bottom: 16px;

  h3 {
    font-family: $font-family-GeometriaBold;
    font-size: 22px;
    margin-bottom: 0;
  }
}

.closeButton {
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-family: Verdana, sans-serif;
  line-height: 0.5;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -7px;
  width: 22px;
  height: 22px;
  outline: none;

  @media (min-width: $media-md) {
    right: -15px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
}

.discount {
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 13px 20px 10px 20px;
  position: relative;
  margin-bottom: 10px;
  background: #f7f8fa;
}

.discountActive {
  border-color: #c3c3c3;
  background: #fff;
}

.discountName {
  color: #1a212b;
  font-size: 14px;
  margin-bottom: 6px;
  font-family: $font-family-GeometriaMedium;

  sup {
    font-size: 9px;
  }
}

.discountValue {
  color: #1a212b;
  font-size: 18px;
  font-family: $font-family-GeometriaBold;
}

// TODO: Вероятно надо сделать отдельный компонент
.checkbox {
  position: absolute;
  right: 10px;
  bottom: 4px;

  :global {
    .aui-checkbox__icon {
      border-width: 2px;

      &:before {
        top: 5px;
        left: 3px;
        width: 12px;
        height: 6px;
        border-bottom-width: 2px;
        border-left-width: 2px;
      }
    }
  }
}

.creditType {
  margin-bottom: 6px;
  font-family: $font-family-GeometriaMedium;

  sup {
    font-size: 9px;
  }
}
