// Grid and scaffolding
$container-max-width: 1170px;

// Fonts Rusbus
$base-font: Verdana, sans-serif;
$font-main: $base-font;
$font-number: $base-font;
$body-base-font: 13px/1.4 $base-font;
$font-family-GeometriaBold: 'GeometriaBold', Arial, FreeSans, sans-serif;
$font-family-GeometriaMedium: 'GeometriaMedium', Arial, FreeSans, sans-serif;

//Colors Rusbus
$color-white: #fff;
$color-dark: #0c121c;
$color-gray: #ced5d8;
$color-gray-lighter: #e4e8e8;
$color-gray-lighter2: #f2f0f0;
$color-text-gray: #797e80;
$color-blue: #0065ad;
$color-green: #98b02e;
$color-colored: #005a2b;
$color-red: #ff0000;

// MEDIA
$media-xs: 480px;
$media-sm: 576px;
$media-md: 768px;
$media-lg: 992px;
$media-xl: 1200px;

%bus-instock-control-reset {
  border: none;
  border-radius: 0;
  background: none;
}

.service {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
  padding: 20px;
  border: 1px solid #cdcdcd;
}
.serviceSelected {
  border-color: $color-blue;
  outline: 1px solid $color-blue;
}

.serviceTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.serviceBody {
  width: 100%;
  margin-bottom: 20px;
}

.serviceBottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: auto;
}

.title {
  font-family: $font-family-GeometriaBold;
  font-size: 18px;
}

.fullDescr {
  margin-bottom: 25px;
}

.textLg {
  font-family: $font-family-GeometriaBold;
  font-size: 18px;
  text-transform: uppercase;
}

.txtRuble {
  color: #929699;
}

.checkbox {
  margin-left: 10px;
}

.btnPopover {
  display: flex;
  align-items: center;
  background: none;
  font-family: $font-family-GeometriaMedium;
  font-size: 12px;
  border: none;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-left: 7px;
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent $color-green;
  }

  &:hover {
    color: $color-blue;
    text-decoration: underline;

    &:after {
      border-color: transparent transparent transparent $color-blue;
    }
  }
}

.popoverHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.btnClose {
  position: relative;
  top: -10px;
  right: -10px;
  margin: 0;
  padding: 0;
  font-size: 20px;
  background: none;
  border: none;
  font-family: Verdana, sans-serif;
  cursor: pointer;
  outline: none;
}
