/* font-family: "GeometriaMedium"; */
@font-face {
  font-family: "GeometriaMedium";
  src: url("../fonts/geometria-medium-webfont.eot");
  src: url("../fonts/geometria-medium-webfont.eot?#iefix")format("embedded-opentype"),
  url("../fonts/geometria-medium-webfont.woff") format("woff"),
  url("../fonts/geometria-medium-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

/* font-family: "GeometriaBold"; */
@font-face {
  font-family: "GeometriaBold";
  src: url("../fonts/geometria-bold-webfont.eot");
  src: url("../fonts/geometria-bold-webfont.eot?#iefix")format("embedded-opentype"),
  url("../fonts/geometria-bold-webfont.woff") format("woff"),
  url("../fonts/geometria-bold-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
